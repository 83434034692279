<template>
    <div class="d-flex h-100vh justify-content-center align-items-center" :class="{'p-3': platform == 'mobile'}">
        <div class="box-register" :class="platform">
            <div class="logo p-2 mb-3 mt-3 rounded">
                <a href="/">
                    <img :src="logo" width="250" alt="logo">
                </a>
            </div>
            <h5 class="text-dark text-uppercase border-bottom pb-3 pt-1">
                <template v-if="success">
                    <div class="text-line">
                        <span>Login</span>
                    </div>
                </template>
                <template v-else>
                    <div class="text-line">
                        <span>Cadastro</span>
                    </div>
                </template>
            </h5>
            <template v-if="success">
                <div class="alert alert-success" role="alert">
                    <h4 class="alert-heading">Parabéns!</h4>
                    <p>Sua conta foi criada com sucesso!</p>
                    <hr>
                    <p class="mb-0">Foi enviado um link para o e-mail (<strong>{{user.email}}</strong>). <br /> Acesse seu e-mail, verifique a <b>caixa de entrada</b> ou <b>spam</b> e clique no link para poder ativar sua conta.</p>
                    <hr>
                    <p class="mb-0">Após ativar sua conta, faça o login abaixo com seu usuário <b>{{username}}</b> e senha.</p>
                </div>
                <form @submit.prevent.stop="login">
                    <div class="form-group">
                        <label class="text-dark">Usuário</label>
                        <input type="text" class="form-control" v-model="username" autocomplete="off" spellcheck="false" readonly required>
                    </div>
                    <div class="form-group">
                        <label class="text-dark">Senha</label>
                        <input type="password" class="form-control" v-model="password" autocomplete="off" spellcheck="false" required>
                    </div>
                    <button class="btn btn-success btn-block mb-3" type="submit" :disabled="username == '' || password == ''">Fazer login</button>              
                </form>
            </template>
            <template v-else>
                <form @submit.prevent.stop="register">
                    <div class="form-group">
                        <label for="name" class="text-dark">Nome</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="inputGroupPrepend3">
                                    <i class="fas fa-signature"></i>
                                </span>
                            </div>
                            <input type="text" class="form-control" id="name" v-model="user.nome" autocomplete="off" spellcheck="false" aria-describedby="inputGroupPrepend3" required>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="lastname" class="text-dark">Sobrenome</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="inputGroupPrepend3">
                                    <i class="fas fa-signature"></i>
                                </span>
                            </div>
                            <input type="text" class="form-control" id="lastname" v-model="user.sobrenome" autocomplete="off" spellcheck="false" aria-describedby="inputGroupPrepend3" required>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="email" class="text-dark">E-mail</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="inputGroupPrepend3">
                                    <i class="fas fa-at"></i>
                                </span>
                            </div>
                            <input type="text" class="form-control" id="email" v-model="user.email" autocomplete="off" spellcheck="false" aria-describedby="inputGroupPrepend3" required>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="tel" class="text-dark">Telefone</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="inputGroupPrepend3">
                                    <i class="fas fa-phone-alt"></i>
                                </span>
                            </div>
                            <the-mask class="form-control" id="tel" v-model="user.telefone" :mask="'(##) # ####-####'" autocomplete="off" spellcheck="false" aria-describedby="inputGroupPrepend3" required />
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="password" class="text-dark">Sua senha</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="inputGroupPrepend3">
                                    <i class="fas fa-key"></i>
                                </span>
                            </div>
                            <input type="password" class="form-control" id="password" v-model="user.senha" autocomplete="off" spellcheck="false" aria-describedby="inputGroupPrepend3" required>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="cpf" class="text-dark">Seu CPF</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="inputGroupPrepend3">
                                    <i class="fas fa-lock"></i>
                                </span>
                            </div>
                            <the-mask class="form-control" id="cpf" v-model="user.cpf" :mask="'###.###.###-##'" autocomplete="off" spellcheck="false" required />
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="date" class="text-dark">Data de nascimento</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="inputGroupPrepend3">
                                    <i class="far fa-calendar"></i>
                                </span>
                            </div>
                            <the-mask class="form-control" id="date" v-model="user.data_nascimento" :mask="'##/##/####'" autocomplete="off" spellcheck="false" required />
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="est" class="text-dark">Seu estado</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="inputGroupPrepend3">
                                    <i class="fas fa-building"></i>
                                </span>
                            </div>
                            <select class="form-control" id="est" v-model="user.estado" @change="loadCitys" required>
                                <option value="" selected>Selecione seu estado</option>
                                <option v-for="(item, index) in estados" :value="item.nome" :key="index">
                                    {{item.nome}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="cid" class="text-dark">Sua cidade</label>
                        <div class="w-100">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="inputGroupPrepend3">
                                        <i class="fas fa-city"></i>
                                    </span>
                                </div>
                                <select class="form-control" id="cid" v-model="user.cidade" :disabled="user.estado == ''" required>
                                    <option value="" selected>Selecione sua cidade</option>
                                    <option v-for="(cidade, index) in cidades" :value="cidade" :key="index">
                                        {{cidade}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <h5 class="text-center text-dark text-uppercase pt-1 pb-3">
                        <div class="text-line mb-3">
                            <span>Dados bancários</span>
                        </div>    
                    </h5>
                    <div class="form-row" :class="{'d-block': platform == 'mobile'}">
                        <div class="col">
                            <div class="form-group">
                                <label class="text-dark">Selecione o banco</label>
                                <div class="w-100">
                                    <div id="virtual-select" data-style="form-control"></div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label class="text-dark">Tipo de conta</label>
                                <select class="form-control" v-model="user.dados_bancarios.tipo" required>
                                    <option value="Corrente" :selected="user.dados_bancarios.tipo == 'Corrente'">Corrente</option>
                                    <option value="Poupança" :selected="user.dados_bancarios.tipo == 'Poupança'">Poupança</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col">
                            <div class="form-group">
                                <label class="text-dark">Agência</label>
                                <input type="text" class="form-control" v-model="user.dados_bancarios.agencia" autocomplete="off" spellcheck="false" required>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label class="text-dark">Conta</label>
                                <input type="text" class="form-control" v-model="user.dados_bancarios.conta" autocomplete="off" spellcheck="false" required>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label class="text-dark">Dígito</label>
                                <input type="text" class="form-control" v-model="user.dados_bancarios.digito" autocomplete="off" spellcheck="false" required>
                            </div>
                        </div>
                    </div>
                     <div class="w-100 text-center p-2 rounded bg-white mb-2 border">
                        <img src="/images/pix-bc-logo-4.png" width="150" border="0" alt="PIX">
                    </div>
                    <div class="form-row" :class="{'d-block': platform == 'mobile'}">
                        <div class="col">
                            <div class="form-group">
                                <label for="pix_type" class="text-dark w-100">
                                    <span>Tipo de chave pix</span>
                                    <div class="float-right text-danger">Obrigatório</div>
                                </label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="inputGroupPrepend3">
                                            <div class="country-flag-24-2x is-br d-inline-block align-top"></div>
                                        </span>
                                    </div>
                                    <select class="form-control" id="pix_type" v-model="user.dados_bancarios.pix_tipo" required>
                                        <option value="cpf_cnpj" :selected="user.dados_bancarios.pix_tipo == 'cpf_cnpj'">CPF/CNPJ</option>
                                        <option value="email" :selected="user.dados_bancarios.pix_tipo == 'email'">E-mail</option>
                                        <option value="celular" :selected="user.dados_bancarios.pix_tipo == 'celular'">Celular</option>
                                        <option value="chave" :selected="user.dados_bancarios.pix_tipo == 'chave'">Chave aleatória</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label for="pix" class="text-dark w-100">
                                    <span>Chave pix</span>
                                    <div class="float-right text-danger">Obrigatório</div>
                                </label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="inputGroupPrepend3">
                                            <i class="fas fa-asterisk"></i>
                                        </span>
                                    </div>
                                    <template v-if="user.dados_bancarios.pix_tipo == 'cpf_cnpj'">
                                        <the-mask class="form-control" id="pix" v-model="user.dados_bancarios.pix" :mask="['###.###.###-##', '##.###.###/####-##']" autocomplete="off" spellcheck="false" required />
                                    </template>
                                    <template v-else-if="user.dados_bancarios.pix_tipo == 'celular'">
                                        <the-mask class="form-control" id="pix" v-model="user.dados_bancarios.pix" :mask="'(##) # ####-####'" autocomplete="off" spellcheck="false" required />
                                    </template>
                                    <template v-else>
                                        <input :type="user.dados_bancarios.pix_tipo == 'email' ? 'email' : 'text'" class="form-control" id="pix" v-model="user.dados_bancarios.pix" autocomplete="off" spellcheck="false" required />
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="promotional_code" class="text-dark w-100">
                            <span>Código promocional</span>
                            <div class="float-right text-danger">Opcional</div>
                        </label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="inputGroupPrepend3">
                                    <i class="fas fa-gift"></i>
                                </span>
                            </div>
                            <input type="text" class="form-control" id="promotional_code" v-model="user.promotional_code" maxlength="15" autocomplete="off" spellcheck="false">
                        </div>
                    </div>
                    <div class="alert alert-secondary" role="alert">
                        <div class="mb-2 d-flex justify-content-center">
                            <vue-recaptcha @verify="onVerify" :sitekey="key" :loadRecaptchaScript="true" @expired="onCaptchaExpired" ref="recaptcha"></vue-recaptcha>
                        </div>
                        <div>Ao clicar em <b>criar conta</b> você concorda totalmente com nossos <a href="/#rules">termos de uso</a>.</div>
                        <hr />
                        <div class="text-center font-bold" v-html="footer"></div>
                    </div>
                    <button class="btn btn-success btn-block mb-3" type="submit" :disabled="user.recaptcha == ''">Criar conta</button>
                </form>
            </template>
        </div>
      <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
    </div>
</template>

<script>
import {api} from '../api'
import Swal from 'sweetalert2'
import {TheMask} from 'vue-the-mask'
import VueRecaptcha from 'vue-recaptcha';
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import 'virtual-select-plugin/dist/virtual-select.min.css'
import 'virtual-select-plugin/dist/virtual-select.min.js'

export default {
    data() {
        return {
            user: {
                cpf: '',
                nome: '',
                email: '',
                senha: '',
                estado: '',
                cidade: '',
                sobrenome: '',
                telefone: '',
                recaptcha: '',
                affiliate_id: 0,
                data_nascimento: '',
                promotional_code: '',
                geolocation: {},
                dados_bancarios: {
                    tipo: 'Corrente',
                    agencia: '',
                    conta: '',
                    digito: '',
                    banco: '',
                    pix: '',
                    pix_tipo: 'cpf_cnpj'
                }
            },
            bancos: [],
            username: '',
            password: '',
            loading: false,
            success: false,
            platform: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? 'mobile' : 'desktop',
            key: '6LeVDLEZAAAAAM4YX6LRdj3qf73kgqVx-dBBXDYH',
            cidades: [],
            estados: []
        }
    },
    components: {
        TheMask,
        Loading,
        VueRecaptcha
    },
    methods: {
        login() {

            const self = this;

            if (self.username == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe o nome de usuário!',
                    'warning'
                );
            } else if (self.password == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe sua senha!',
                    'warning'
                );
            } else {

                self.loading = true;

                api.post('login', {
                    username: self.username,
                    password: self.password
                }).then((response) => {
                    switch(response.data.result) {
                        case 'success':
                            self.startSession(response.data);
                        break;
                        default:
                            Swal.fire(
                                'Atenção!',
                                response.data.message,
                                'warning'
                            );
                    }
                }).catch((error) => {
                    try {
                        Swal.fire(
                            'Atenção!',
                            self.network_erros[error.status]['message'],
                            'error'
                        );
                    } catch(e) {
                        Swal.fire(
                            'Atenção!',
                            self.network_erros[408]['message'],
                            'error'
                        );
                    }
                }).finally(() => {
                    self.loading = false;
                });
            }
        },
        register() {

            const self = this;

            if (self.user.nome.trim() == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe seu nome!',
                    'warning'
                );
            } else if (self.user.sobrenome.trim() == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe seu sobrenome!',
                    'warning'
                );
            } else if (self.user.email.trim() == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe seu e-mail!',
                    'warning'
                );
            } else if (!self.validateEmail(self.user.email)) {
                Swal.fire(
                    'Atenção!',
                    'O endereço de e-mail é inválido!',
                    'warning'
                );
            } else if (self.user.telefone.trim() == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe seu número de telefone!',
                    'warning'
                );
            } else if (self.user.senha == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe sua senha!',
                    'warning'
                );
            } else if (self.user.senha.toString().length < 4) {
                Swal.fire(
                    'Atenção!',
                    'Sua senha tem que ter no mínimo 4 caracteres!',
                    'warning'
                );
            } else if (self.user.cpf.trim() == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe seu CPF!',
                    'warning'
                );
            } else if (!self.isValidCPF(self.user.cpf)) {
                Swal.fire(
                    'Atenção!',
                    'O CPF informado é inválido!',
                    'warning'
                );
            } else if (self.user.data_nascimento.trim() == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe sua data de nascimento!',
                    'warning'
                );
            } else if (self.user.estado == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe o seu estado!',
                    'warning'
                );
            } else if (self.user.cidade == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe o sua cidade!',
                    'warning'
                );
            } else if (self.user.dados_bancarios.banco == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe o seu banco!',
                    'warning'
                );
            } else if (self.user.dados_bancarios.tipo == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe o seu tipo de conta!',
                    'warning'
                );
            } else if (self.user.dados_bancarios.agencia == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe a agência do seu banco!',
                    'warning'
                );
            } else if (self.user.dados_bancarios.conta == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe sua conta!',
                    'warning'
                );
            } else if (self.user.dados_bancarios.digito == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe o dígito da sua conta!',
                    'warning'
                );
            } else if (self.user.dados_bancarios.pix_tipo == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe o tipo da sua chave pix!',
                    'warning'
                );
            } else if (self.user.dados_bancarios.pix.toString().trim() == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe a sua chave pix!',
                    'warning'
                );
            } else if (self.user.recaptcha == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe se você não é um robô!',
                    'warning'
                );
            } else {

                self.loading = true;
                self.success = false;

                api.post('register', {
                    user: self.user
                }).then((response) => {
                    switch(response.data.result) {
                        case 'success':
                            self.success = true;
                            self.username = response.data.username;
                        break;
                        default:
                            Swal.fire(
                                'Atenção!',
                                response.data.message,
                                'warning'
                            );
                        break;
                    }
                }).catch((error) => {
                    try {
                        Swal.fire(
                            'Falha',
                            self.network_erros[error.status]['message'],
                            'error'
                        );
                    } catch(e) {
                        Swal.fire(
                            'Falha',
                            self.network_erros[408]['message'],
                            'error'
                        );
                    }
                }).finally(() => {
                    self.loading = false;
                    self.onCaptchaExpired();
                });
            }
        },
        getBanks() {

            const self = this;

            self.loading = true;

            api.get('banks').then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.bancos = self.removeDuplicates(response.data.banks, 'Name').sort((a, b) => {
                            return a.Name.localeCompare(b.Name);
                        }).map((item) => {
                            return {
                                Name: [item.Code, item.Name].join(' - ')
                            }
                        });
                    break;
                    default:
                        Swal.fire(
                            'Atenção!',
                            response.data.message,
                            'error'
                        );
                    break;
                }
            }).catch((error) => {
                try {
                    Swal.fire(
                        'Falha',
                        self.network_erros[error.status]['message'],
                        'error'
                    );
                } catch(e) {
                    Swal.fire(
                        'Falha',
                        self.network_erros[408]['message'],
                        'error'
                    );
                }
            }).finally(() => {
                self.loading = false;
            });
        },
        getStates() {

            const self = this;

            self.loading = true;

            api.get('get-states').then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.estados = response.data.states.sort((a, b) => {
                            return a.nome.localeCompare(b.nome);
                        });
                    break;
                    default:
                        Swal.fire(
                            'Atenção!',
                            response.data.message,
                            'error'
                        );
                    break;
                }
            }).catch((error) => {
                try {
                    Swal.fire(
                        'Falha',
                        self.network_erros[error.status]['message'],
                        'error'
                    );
                } catch(e) {
                    Swal.fire(
                        'Falha',
                        self.network_erros[408]['message'],
                        'error'
                    );
                }
            }).finally(() => {
                self.loading = false;
            });
        },
        loadCitys(event) {
            try {
                const estado = event.target.value;
                if (estado == '') {
                    this.cidades = [];
                } else {
                    this.cidades = this.estados.find((item) => item.nome == event.target.value).cidades;
                }
            } catch(e) {
                this.cidades = [];
            }
        },
        onVerify(response) {
            this.user.recaptcha = response;
        },
        onCaptchaExpired() {
            this.user.recaptcha = '';
            this.$refs.recaptcha.reset();
        },
        startSession(data) {
            this.setItem('x-token', data.token).then(() => {
                this.setItem('auth', JSON.stringify(data.user)).then(() => {
                    this.setItem('firebase:pipe', data.user.pipe).then(() => {
                        window.location.href = '/';
                    });
                });
            });
        },
        setItem(key, value) {
            return Promise.resolve().then(() => {
                window.localStorage.setItem(key, value);
            });
        },
        isValidCPF(cpf) {

            var add;
            var rev;

            cpf = cpf.replace(/[^\d]+/g,'');  

            if(cpf == '') return false; 
            // Elimina CPFs invalidos conhecidos  
            if (cpf.length != 11 || 
                cpf == "00000000000" || 
                cpf == "11111111111" || 
                cpf == "22222222222" || 
                cpf == "33333333333" || 
                cpf == "44444444444" || 
                cpf == "55555555555" || 
                cpf == "66666666666" || 
                cpf == "77777777777" || 
                cpf == "88888888888" || 
                cpf == "99999999999")
                return false;   
            // Valida 1o digito 
            add = 0;  
            for (var i=0; i < 9; i ++)    
                add += parseInt(cpf.charAt(i)) * (10 - i);  
                rev = 11 - (add % 11);  
                if (rev == 10 || rev == 11)   
                rev = 0;  
                if (rev != parseInt(cpf.charAt(9)))   
                return false;   
            // Valida 2o digito 
            add = 0;  
            for (var j = 0; j < 10; j ++)   
                add += parseInt(cpf.charAt(j)) * (11 - j);  
            rev = 11 - (add % 11);  
            if (rev == 10 || rev == 11) 
                rev = 0;  
            if (rev != parseInt(cpf.charAt(10)))
                return false;   
            return true;       
        },
        validateEmail(mail) {
            if (/\S+@\S+\.\S+/.test(mail)) {
                return true
            } else {
                return false;
            }
        },
        getAffiliate() {
            
            const rex = /^[a-z0-9]+$/g;
            const affiliate_id = window.sessionStorage.getItem('affiliate_id');

            if (affiliate_id && rex.test(affiliate_id) && affiliate_id.toString().length == 8) {
                this.user.affiliate_id = affiliate_id;
            }
        },
        removeDuplicates(array, key) {
            return array.reduce((arr, item) => {
                const removed = arr.filter(i => i[key] !== item[key]);
                return [...removed, item];
            }, []);
        },
        // getIdade(data) {
        //     var hoje = new Date();
        //     var [MM, DD, YYY] = data.split('/');
        //     var nascimento = new Date(convertDateMMDDYYY(data.split("/")));

        //     //Retorna a diferença entre hoje e a data de nascimento em anos.
        //     var ano = hoje.getFullYear() - nascimento.getFullYear();
        
        //     //Retorna a diferença de mês do mês de nascimento para o atual.
        //     var m = hoje.getMonth() - nascimento.getMonth();

        //     //Caso ainda não tenha ultrapassado o dia e o mês
        //     if (m < 0 || (m === 0 && hoje.getDate() < nascimento.getDate())) {
        //         ano--;
        //     }
        //     return ano;
        // }
    },
    computed: {
        nome() {
            return this.user.nome;
        },
        senha() {
            return this.user.senha;
        },
        dadosBancariosConta() {
            return this.user.dados_bancarios.conta;
        },
        dadosBancariosAgencia() {
            return this.user.dados_bancarios.agencia;
        },
        dadosBancariosDigito() {
            return this.user.dados_bancarios.digito;
        },
        codigoPromocional() {
            return this.user.promotional_code;
        }
    },
    watch: {
        nome(value) {
            this.user.nome = value.replace(/[^a-zà-ú\s]+/gi, '');
        },
        senha(value) {
            this.user.senha = value.replace(/[^a-zA-Z0-9]/g, '');
        },
        username(value) {
            return this.user.username = value.replace(/[^a-z0-9.]/g, '');
        },
        password(value) {
            return this.user.password = value.replace(/[^a-zA-Z0-9]/g, '');
        },
        codigoPromocional(value) {
            return this.user.promotional_code = value.toString().toLowerCase().replace(/[^a-z0-9]/g, '');
        },
        dadosBancariosConta(value) {
            try {
                this.user.dados_bancarios.conta = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        dadosBancariosAgencia(value) {
            try {
                this.user.dados_bancarios.agencia = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        dadosBancariosDigito(value) {
            try {
                this.user.dados_bancarios.digito = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        estados() {
            this.getBanks();
        },
        bancos(items) {
            window.VirtualSelect.init({
                ele: '#virtual-select',
                search: true,
                placeholder: 'Selecione um banco',
                noOptionsText: 'Nenhuma opção disponível',
                noSearchResultsText: 'Nenhum resultado encontrado',
                searchPlaceholderText: `Buscar banco... (${items.length})`,
                hasOptionDescription: true,
                options: items.map(item => {
                    return {
                        label: item.Name,
                        value: item.Name
                    }
                })
            });
        },
    },
    mounted() {

        const self = this;

        if (document.querySelector('#virtual-select')) {
            document.querySelector('#virtual-select').addEventListener('change', function() {
                self.user.dados_bancarios.banco = this.value;
            });
        }
    },
    created() {
        this.getStates();
        this.getAffiliate();
        this.user.geolocation = this.geolocation;

        window.addEventListener('resize', () => {
            this.platform = this.isMobile();
        });
    }
}
</script>

<style scoped>
.h-100vh {
    height: calc((100vh + 100%) + 1px);
    background-color: #e5e5e5;
}
.box-register {
    margin: 0 auto;
}
.box-register.desktop {
    width: 500px;
}
.box-register.mobile {
    width: 100%;
}
.input-group-text {
    min-width: 50px;
    justify-content: center;
}
.logo {
    text-align: center;
    background-color: var(--foreground);
}
.text-line {
    height: 1px;
    margin-top: 1em;
    background-color: #ced4da;
    text-align: center;
}
.text-line span {
    background-color: #e5e5e5;
    position: relative;
    top: -.65em;
    padding: 0 10px;
}
</style>