import { render, staticRenderFns } from "./RulesClients.vue?vue&type=template&id=412d7630&scoped=true&"
import script from "./RulesClients.vue?vue&type=script&lang=js&"
export * from "./RulesClients.vue?vue&type=script&lang=js&"
import style0 from "./RulesClients.vue?vue&type=style&index=0&id=412d7630&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "412d7630",
  null
  
)

export default component.exports